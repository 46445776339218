import { defineStore } from "pinia";
import { useBcPersistStore } from "./bcPersist";
import { useUserPersistStore } from "./userPersist";
import {
  formatDateWithDay,
  formatJSDateToBCDate,
  getUserNameFromEmail,
} from "@/utils/Utils";

export const useHRStore = defineStore("hr", {
  state: () => {
    return {
      isInitialized: false,
      approvals: [],
      humanResourcesSetup: null,
      typesOfAbsence: [],
      causesOfAbsences: [],
      employeeData: {},
      employeeAbsences: [],
      absencesFromTo: [],
    };
  },
  getters: {
    openApprovals() {
      return this.approvals?.filter((x) => x.status === "Submitted");
    },

    isApprovalAdmin() {
      const userPersist = useUserPersistStore();
      if (!this.humanResourcesSetup?.approvalAdminEmail) {
        userPersist.pushError({
          message:
            "Approval Admin Email is not set in the Human Resources Setup",
        });
        return false;
      }
      return (
        getUserNameFromEmail(
          this.humanResourcesSetup.approvalAdminEmail
        ).toLowerCase() ===
        getUserNameFromEmail(
          userPersist.linkedResource.userAuthenticationEmail
        ).toLowerCase()
      );
    },
    isAbsenceApprovalAdmin() {
      const userPersist = useUserPersistStore();
      if (!this.humanResourcesSetup?.approvalPlannerEmail) {
        userPersist.pushError({
          message:
            "Approval Planner Email is not set in the Human Resources Setup",
        });
        return false;
      }

      return (
        this.humanResourcesSetup.absenceApprovalByPlanner &&
        getUserNameFromEmail(
          this.humanResourcesSetup.approvalPlannerEmail
        ).toLowerCase() ===
          getUserNameFromEmail(
            userPersist.linkedResource.userAuthenticationEmail
          ).toLowerCase()
      );
    },

    getAbsenceCodeDescription() {
      return (code) => {
        return this.causesOfAbsences.find((x) => x.code === code)?.description;
      };
    },

    upcomingAbsences() {
      const absences = this.employeeAbsences?.filter((x) => {
        return (
          new Date(x.fromDate) >= new Date() && x.status.includes("Approved")
        );
      });
      console.log("upcomingAbsences", absences);

      absences.sort((a, b) => {
        return a.fromDate.localeCompare(b.fromDate);
      });
      return absences;
    },
  },
  actions: {
    async initializeHR() {
      console.time("initializeHR");
      await this.getHumanResourcesSetup();
      await this.getApprovals();
      console.timeEnd("initializeHR");
      this.isInitialized = true;
    },

    /**
     * example of the data returned for getHumanResourcesSetup
     * @returns {
        "@odata.etag": "W/\"JzE5OzYwNzIwNzE0ODAzNDA4NjE0NDIxOzAwOyc=\"",
        "id": "9cfd78ee-11ab-ec11-bb91-000d3a4c1279",
        "approvalAdminName": "Pascale",
        "approvalAdminEmail": "PascaleJ@but.be",
        "approvalPlannerName": "Marc",
        "approvalPlannerEmail": "MarcW@but.be",
        "overtimeApprovalRequired": true,
        "absenceApprovalByPlanner": true,
        "absenceCodeOvertimeRecovery": "REC",
        "absenceCodeIlness": "ILL",
        "absenceCodeHoliday": "HOL",
        "absenceCodePublicHoliday": "HLT",
        "publicHolidayCompensationThisYear": 2,
        "publicHolidayCompensationNextYear": 1
      }
     */
    getHumanResourcesSetup() {
      const userPersist = useUserPersistStore();
      return useBcPersistStore()
        .getButApiData({
          companyGuid: userPersist.linkedResource.companyGuid,
          urlSegment: `humanresourcessetup`,
          // urlSegment: `humanresourcessetup?$filter=resourceNumber eq '${userPersist.linkedResource.no}'`,
        })
        .then((res) => {
          this.humanResourcesSetup = res.data.value[0];
        })
        .catch((error) => {
          userPersist.pushError({
            message: "Error while loading Human Resources Setup",
            error,
          });
        });
    },

    /**
     * example of the data returned for getOvertimeApprovals
     * @returns [
     *  {
          "@odata.etag": 'W/"JzE5OzIwNzM0Mzc3MzY2NDE0NzQ0NTUxOzAwOyc="',
          id: "99e4fa15-a845-ef11-a316-000d3a49d89d",
          employeeNo: "EMB001",
          employeeName: "Marc Wauters",
          entryNo: 19,
          date: "2024-07-21",
          projectNo: "",
          projectManager: "",
          comment: "",
          quantity: 1,
          status: "Submitted",
          rejectionReason: "",
          lastModifiedDateTime: "2024-07-19T08:23:21.77Z",
          type: "Overtime",
        }, 
        ... 
      ]
     */
    getApprovals() {
      const userPersist = useUserPersistStore();
      const bcPersist = useBcPersistStore();

      const promises = [];

      //get absence approvals if user is an admin or an approval admin
      const isAdmin = userPersist.linkedResource.timeSheetUserType === "Admin";

      if (this.humanResourcesSetup?.overtimeApprovalRequired) {
        if (
          this.humanResourcesSetup.absenceApprovalByPlanner &&
          (isAdmin || this.isAbsenceApprovalAdmin)
        ) {
          promises.push(
            bcPersist.getButApiData({
              companyGuid: userPersist.linkedResource.companyGuid,
              urlSegment: `employeeovertimes?$filter=status eq 'Submitted'`,
            })
          );
        } else {
          //admins can approve all submitted overtime requests without a project manager filled in
          if (userPersist.linkedResource.timeSheetUserType === "Admin") {
            promises.push(
              bcPersist.getButApiData({
                companyGuid: userPersist.linkedResource.companyGuid,
                urlSegment: `employeeovertimes?$filter=projectManager eq '' and status eq 'Submitted'`,
              })
            );
          }

          //project managers can approve all submitted overtime requests where they are the project manager
          promises.push(
            bcPersist.getButApiData({
              companyGuid: userPersist.linkedResource.companyGuid,
              urlSegment: `employeeovertimes?$filter=projectManager eq '${userPersist.linkedResource.userName}' and status eq 'Submitted'`,
            })
          );
        }
      }

      if (isAdmin || this.isApprovalAdmin || this.isAbsenceApprovalAdmin) {
        //get all submitted absence requests that need approval
        promises.push(
          bcPersist.getButApiData({
            companyGuid: userPersist.linkedResource.companyGuid,
            urlSegment: `employeeabsences?$filter=status eq 'Submitted'`,
          })
        );
      }
      /**
       * absences example:
           {
                "@odata.etag": "W/\"JzIwOzE3OTc0MzU0MTQxNDQ5NTgzOTU2MTswMDsn\"",
                "id": "f72a269c-4f4e-ef11-bfe2-6045bd9730c9",
                "employeeNo": "EMB007",
                "entryNo": 7,
                "fromDate": "2024-07-30",
                "toDate": "2024-07-30",
                "causeofAbsence": "HOL",
                "description": "Holiday",
                "userComment": "",
                "quantity": 0.5,
                "unitOfMeasure": "DAY",
                "AMPM": "PM",
                "status": "Submitted",
                "rejectionReason": "",
                "lastModifiedDateTime": "2024-07-30T08:42:11.11Z"
            }

        overtimes example:
            {
                "@odata.etag": "W/\"JzE5OzIwNzM0Mzc3MzY2NDE0NzQ0NTUxOzAwOyc=\"",
                "id": "99e4fa15-a845-ef11-a316-000d3a49d89d",
                "employeeNo": "EMB001",
                "employeeName": "Marc Wauters",
                "entryNo": 19,
                "date": "2024-07-21",
                "projectNo": "",
                "projectManager": "",
                "comment": "",
                "quantity": 1,
                "status": "Submitted",
                "rejectionReason": "",
                "lastModifiedDateTime": "2024-07-19T08:23:21.77Z"
            }
       */

      return Promise.all(promises)
        .then((res) => {
          const r = res
            .flat()
            .map((x) => {
              return x.data.value;
            })
            .flat();
          r.forEach((approval) => {
            // console.log("approval", approval);
            //add a type depending on the absence or overtime
            approval.type = approval.causeofAbsence ? "Absence" : "Overtime";
            if (approval.type === "Absence") {
              approval.dateString =
                formatDateWithDay(approval.fromDate) +
                " - " +
                formatDateWithDay(approval.toDate);
              approval.reportsTo = this.getReportsToForEmployeeNo(
                approval.employeeNo
              );
            } else {
              approval.dateString = formatDateWithDay(approval.date);
            }
            if (!approval.employeeName) {
              const resourceNo = bcPersist.getResourceNoForEmployeeNo(
                approval.employeeNo
              );
              approval.employeeName =
                bcPersist.resources.find((x) => x.no === resourceNo)?.name ||
                "N/A";
            }
          });
          // console.log("getApprovals", r);
          this.approvals = r;
          //filter out the approvals that are not for the user's reportTo
        })
        .catch((error) => {
          console.error("getApprovals", error);
          userPersist.pushError({
            message: "Error while loading Approvals",
            error,
          });
        });
    },

    initializeAbsences() {
      useBcPersistStore().getHolidays(); //required for getAbsenceInfoBetweenDates

      const promises = [];
      promises.push(this.getEmployeeData());
      promises.push(this.getCauseOfAbsenceData());
      promises.push(this.getAbsenceDataForLinkedEmployee());

      useBcPersistStore().getHolidays(); //update Holidays in the persist store for later use

      return Promise.all(promises);
    },

    getEmployeeData() {
      const userPersist = useUserPersistStore();
      const config = {
        companyGuid: userPersist.linkedResource.companyGuid,
        urlSegment: `employees?$filter=no eq '${userPersist.linkedEmployeeNo}'`,
      };
      return useBcPersistStore()
        .getButApiData(config)
        .then((res) => {
          this.employeeData = res?.data?.value[0];
        });
    },

    getCauseOfAbsenceData() {
      const userPersist = useUserPersistStore();
      const isAdmin = userPersist.linkedResource.timeSheetUserType === "Admin";
      let filter = `?$filter=hiddenFromUser eq false`;
      //admins can see all causes of absence, also the hidden ones
      if (isAdmin) {
        filter = "";
      }
      return useBcPersistStore()
        .getButApiData({
          companyGuid: userPersist.linkedResource.companyGuid,
          urlSegment: `causesofabsence${filter}`,
        })
        .then((res) => {
          if (res?.data?.value?.length > 0) {
            // console.log("causesofabsence", res.data.value);
            // Sort causes of absence, with HOL first and then alphabetically by description
            this.causesOfAbsences = res.data.value.sort((a, b) => {
              if (a.code === this.humanResourcesSetup.absenceCodeHoliday)
                return -1;
              if (b.code === this.humanResourcesSetup.absenceCodeHoliday)
                return 1;
              return a.description.localeCompare(b.description);
            });
            // console.log("causesOfAbsences", this.causesOfAbsences);
          }
        })
        .catch((error) => {
          userPersist.pushError({
            message: "Error while loading Cause Of Absence Data",
            error,
          });
        });
    },

    getAbsenceDataForLinkedEmployee() {
      const userPersist = useUserPersistStore();
      return useBcPersistStore()
        .getButApiData({
          companyGuid: userPersist.linkedResource.companyGuid,
          urlSegment: `employeeabsences?$filter=employeeNo eq '${userPersist.linkedEmployeeNo}'&$top=100&$orderby=fromDate DESC`,
        })
        .then((res) => {
          if (res?.data?.value?.length > 0) {
            // console.log("causesofabsence", res.data.value);
            this.employeeAbsences = res.data.value;
            // .sort(
            //   (a, b) => new Date(b.fromDate) - new Date(a.fromDate)
            // );
          }
        })
        .catch((error) => {
          userPersist.pushError({
            message: "getAbsenceDataForLinkedEmployee",
            error,
          });
        });
    },

    getAbsenceDataFromTo(
      toDate,
      fromDate,
      selectedPlanningCompany,
      merge = false
    ) {
      const userPersist = useUserPersistStore();
      return useBcPersistStore()
        .getButApiData({
          companyGuid: selectedPlanningCompany,
          urlSegment: `employeeabsences?$filter=toDate ge ${toDate
            .toISOString()
            .substr(0, 10)} AND fromDate le ${fromDate
            .toISOString()
            .substr(0, 10)} and status ne 'Rejected'`,
        })
        .then((res) => {
          if (merge) {
            if (res?.data?.value?.length > 0) {
              this.absencesFromTo = this.absencesFromTo.concat(res.data.value);
            }
          } else {
            if (res?.data?.value?.length > 0) {
              this.absencesFromTo = res.data.value;
            } else {
              this.absencesFromTo = [];
            }
          }
        })
        .catch((error) => {
          userPersist.pushError({
            message: "Error while loading Absence Data (From To)",
            error,
          });
        });
    },

    isPublicHoliday(dateToCheck) {
      const dateToCheckString = formatJSDateToBCDate(dateToCheck);
      for (const holiday of useBcPersistStore().holidays) {
        // console.log("holiday", holiday.recurringSystem, holiday);

        if (holiday.recurringSystem === "Annual Recurring") {
          //replace the year with the year of the currentDate
          holiday.date = dateToCheck.getFullYear() + holiday.date.substr(4);
        }
        if (
          holiday.nonworking &&
          holiday.companyGuid ===
            useUserPersistStore().linkedResource.companyGuid &&
          holiday.date === dateToCheckString
        ) {
          return true;
        }
      }
      return false;
    },

    /**
     * 1. Count nr of days between startDate and endDate and keep track of an array of items for each day in the range
     *    and fill the daily regime for each day, but recalculate the hours to half days.
     *    eg: <3 hours = 0 day, >=6 hours = 1 day, between 3 and 6 hours = 0.5 day
     *    The dailyRegime:
     *        eg: the nr of working hours for Monday: userPersist.linkedResource.employeesPTE[0].dailyRegimeMo
     *        eg: the nr of working hours for Tuesday: userPersist.linkedResource.employeesPTE[0].dailyRegimeTu
     * 2. Loop date range and check each day if the day is a public holiday: eg: iterate useBcPersistStore().holidays
     *    Filter out the ones for the user's userPersist.linkedResource.companyGuid
     *    Use the date field to check for a holiday. It is always a full day.
     * 3. Check if the daterange does not overlap with already existing absence records. (this.employeeAbsences)
     *    and iterate fromDate to toDate (format="yyyy-mm-dd")
     * 4. Check if the day is a public holiday and set workingDay to 0
     *
     * @param startDateString eg: "2022-08-01"
     * @param endDateString eg: "2022-08-02"
     * @param amPm eg: "FullDay", "AM", "PM"
     * @returns {
     *  sumOfWorkingDays <number>,
     *  overlappingAbsences <array>,
     * }
     */
    getAbsenceInfoBetweenDates(
      startDateString,
      endDateString,
      amPm = "FullDay"
    ) {
      try {
        let overlappingAbsences = [];
        const userPersist = useUserPersistStore();
        const bcPersist = useBcPersistStore();
        const workingDays = []; //all dates between startDate and endDate that are working days
        const dailyRegime = {
          Monday: userPersist.linkedResource.employeesPTE[0].dailyRegimeMo,
          Tuesday: userPersist.linkedResource.employeesPTE[0].dailyRegimeTu,
          Wednesday: userPersist.linkedResource.employeesPTE[0].dailyRegimeWe,
          Thursday: userPersist.linkedResource.employeesPTE[0].dailyRegimeTh,
          Friday: userPersist.linkedResource.employeesPTE[0].dailyRegimeFr,
          Saturday: userPersist.linkedResource.employeesPTE[0].dailyRegimeSa,
          Sunday: userPersist.linkedResource.employeesPTE[0].dailyRegimeSu,
        };

        const fromDate = new Date(startDateString);
        const toDate = new Date(endDateString);

        let currentDate = new Date(fromDate);
        while (currentDate <= toDate) {
          const currentDateString = formatJSDateToBCDate(currentDate);
          const dayName = currentDate.toLocaleDateString("en-US", {
            weekday: "long",
          });

          //fill the daily regime for each day, but recalculate the hours to half days.
          const dailyRegimeHours = dailyRegime[dayName];
          const dailyRegimeDays =
            dailyRegimeHours < 3 ? 0 : dailyRegimeHours >= 6 ? 1 : 0.5;

          //init with days to work from the daily regime
          let workingDay = dailyRegimeDays; //0 = not a working day, 0.5 = half day, 1 = full day

          // Check if the day is a public holiday
          workingDay = this.isPublicHoliday(currentDate) ? 0 : workingDay;

          if (workingDay > 0) {
            //iterate the existing absence records and reduce the workingDay if the day is already an absence
            for (const absence of this.employeeAbsences) {
              //if currentDate is in range and is (auto)approved, then set the workingDay to 0
              if (
                currentDateString >= absence.fromDate &&
                currentDateString <= absence.toDate &&
                !absence.status.toLowerCase().includes("rejected")
              ) {
                //if absence is a full day, then set workingDay to 0, else subtract 0.5 if not yet 0 and if the amPm is the same
                if (absence.AMPM === "FullDay") {
                  workingDay = 0;
                  overlappingAbsences.push(absence);
                } else {
                  //absence is half a day
                  if (amPm === "FullDay") {
                    //subtract half a day
                    workingDay = Math.max(0, workingDay - 0.5);
                    overlappingAbsences.push(absence);
                  } else if (absence.AMPM === amPm) {
                    //absence is half a day and the same as the current amPm
                    workingDay = 0;
                    overlappingAbsences.push(absence);
                  } else {
                    //subtract half a day
                    workingDay = Math.max(0, workingDay - 0.5);
                    //no overlap
                  }
                }
              }
            }
          }

          workingDays.push({
            date: currentDate,
            dateString: currentDateString,
            day: dayName,
            workingDay,
          });

          currentDate.setDate(currentDate.getDate() + 1);
        }

        let sumOfWorkingDays = 0;
        //sum up the working days for this range
        for (const workingDay of workingDays) {
          sumOfWorkingDays += workingDay.workingDay;
        }
        const r = {
          sumOfWorkingDays,
          overlappingAbsences,
        };
        console.log("getAbsenceInfoBetweenDates", r);
        return r;
      } catch (error) {
        useUserPersistStore().pushError(error);
      }
    },
    getReportsToForEmployeeNo(employeeNo) {
      console.log("getReportsToForEmployeeNo", employeeNo);

      // const userPersist = useUserPersistStore();
      // const bcPersist = useBcPersistStore();
      // const resourceNo = bcPersist.getResourceNoForEmployeeNo(employeeNo);
      // const resource = bcPersist.resources.find((x) => x.no === resourceNo);
      // if (resource) {
      //   return resource.reportsTo;
      // }
      return "";
    },
  },
});
